import React from 'react'
import {
  useCart,
  useCheckoutUrl,
  useCartItems,
  useCartCount,
  useRemoveItemFromCart,
} from 'gatsby-theme-shopify-manager'
import { Location } from '@reach/router'
import MainLayout from '../layouts/MainLayout'
import LineItem from '../components/LineItemComponent/LineItemComponent'

const SHOW_QUANTITY_CONTROLS = false

const CartPage = () => {
  const cartCount = useCartCount()
  const checkoutUrl = useCheckoutUrl()
  const cart = useCart()
  const cartItems = useCartItems()
  const removeItemFromCart = useRemoveItemFromCart()

  const removeFromCart = async (productId) => {
    try {
      await removeItemFromCart(productId, 1)
    } catch (err) {
      console.error(err)
    }
  }

  if (cartCount === 0 || cart == null) {
    return (
      <MainLayout>
        <div className="flex flex-col justify-center h-48">
          <div className="bg-white overflow-hidden mx-auto max-w-screen">
            Your cart is empty.
          </div>
        </div>
      </MainLayout>
    )
  }

  return (
    <MainLayout>
      <div className="bg-white flex flex-col overflow-hidden mx-auto max-w-2xl">
        <ul className="flex flex-col order-2 md:order-1">
          {cartItems.map((item) => {
            const { title, variant, quantity } = item
            return (
              <li key={variant.id}>
                <div className="flex items-start px-4 py-4 sm:px-6 hover:bg-gray-200 transition duration-150 ease-in-out">
                  <div className="min-w-0 flex-1 flex items-start">
                    <div className="flex-shrink-0">
                      <img className="w-16" src={variant.image.src} alt="" />
                    </div>
                    <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                      <div>
                        <div>{title}</div>
                        <div className="text-sm leading-5 text-gray-900">
                          {variant.title}
                        </div>
                        <div className="mt-2 flex items-start text-sm leading-5 text-gray-500">
                          <div className="truncate">Quantity: {quantity}</div>
                          <div className="mx-4">|</div>
                          <button onClick={() => removeFromCart(variant.id)}>
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="font-bold">${variant.price}</div>
                </div>
              </li>
            )
          })}
        </ul>
        <div className="flex order-1 md:order-2 px-6 justify-end">
          <div className="w-full md:w-80 p-6 mt-8 bg-gray-200 rounded-md mb-4">
            <div className="mb-8">
              <span className="text-xl mr-2">Subtotal:</span>
              <span className="text-xl font-bold">${cart.totalPrice}</span>
            </div>
            <a
              href={checkoutUrl}
              className="flex justify-center w-full py-4 font-medium text-white uppercase bg-black rounded-md hover:bg-gray-700 focus:outline-none"
            >
              Proceed to checkout
            </a>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default CartPage
